import React, { useEffect, useState } from "react";

import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { Auth } from "aws-amplify";

import UserMenu from "./UserMenu.js";
import MenuItems from "./MenuItems";

// ******************************************************
//   (Lazy) load the components...
// ******************************************************

const Play           = React.lazy(() => import("./Play.js"));
const UserPage       = React.lazy(() => import("./UserPage.js"));
const ChangePassword = React.lazy(() => import("../auth/SignInChangePassword.js"));

const Projects         = React.lazy(() => import("./Projects.js"));

// ******************************************************
//   How the side menu draw moves...
// ******************************************************
const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function DashboardContent(props) {

  // ******************************************************
  //   Handle menu click events...
  // ******************************************************

  const [displayComponent, setDisplayComponent] = useState(
    // Default component...
    <Play name="This is one." />
  );

  // Switch values set in the 'id' of the source element.
  // Send this function as a prop to the menu components.
  function changeComponent(target) {
    switch (target) {
      case "Projects":
        setDisplayComponent(<Projects />);
        break;
      case "UserPage":
        setDisplayComponent(<UserPage name="This is three." />);
        break;
      case "ChangePassword":
        setDisplayComponent(<ChangePassword />);
        break;
      case "SignOut":
          window.location.replace("./signout");
          break;
      default:
        setDisplayComponent(<Play name="This is the default page." />);
        break;
    }
  }

  // ******************************************************
  //   The left hand manu draw...
  // ******************************************************

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  // ******************************************************
  //   The page template...
  // ******************************************************

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>

        <CssBaseline />
        
        {/* Bar at the top of the page... */}
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {props.title}
            </Typography>

            <UserMenu clickHandler={changeComponent}/>

          </Toolbar>
        </AppBar>

        {/* Container for the menu on the left... */}
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">

            {/* Left Hand Menu Items... */}
            <MenuItems clickHandler={changeComponent}/>

          </List>
        </Drawer>

        {/* Main page content... */}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >

          {/* Push content down from under main page toolbar... */}
          <Toolbar />

          {/* Container for the main content... */}
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>

                  {/* The content component itself... */}
                  {displayComponent}
                
                </Paper>
              </Grid>
            </Grid>
          </Container>

        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function page(props) {

  // ******************************************************
  //   Look for authenticated user...
  // ******************************************************

  const [authPage, setAuthPage] = useState("Loading...");

  async function getCurrentSession() {
    try {
      await Auth.currentSession({ bypassCache: false });
      setAuthPage(<DashboardContent title={props.title}/>);
    } catch (error) {
      window.location.replace("./signin");
    }
  }

  // Trigger getting session details...
  useEffect(() => {
    getCurrentSession();
  }, []);

  // ******************************************************
  //   Render the page content...
  // ******************************************************
  return <React.Fragment>{authPage}</React.Fragment>;
}

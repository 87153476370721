import * as React from "react";
import { Link } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";

export default function MenuItems(props) {
  return (
    <React.Fragment>
      
      <ListItemButton onClick={(e) => props.clickHandler("Projects")}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="AWS Accounts" />
      </ListItemButton>

      <ListItemButton onClick={(e) => props.clickHandler("UserPage")}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="UserPage" />
      </ListItemButton>

      <ListItemButton onClick={(e) => props.clickHandler("ChangePassword")}>
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="ChangePassword" />
      </ListItemButton>

      <ListItemButton onClick={(e) => props.clickHandler("SignOut")}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="SignOut" />
      </ListItemButton>

    </React.Fragment>
  );
}

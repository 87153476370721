const awsconfig =  {
    "aws_project_region": "us-west-2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_gC4wxBYug",
    "aws_user_pools_web_client_id": "2iuamg7tp67drbdl9gdc9h0f5m",
    "API": {
        "endpoints": [
            {
                "name": "mikes-test-project-api", // name of the API in API Gateway console
                "endpoint": "https://api.aws-demo-site.com",
                "region": "us-west-2",
                "paths": ['/bar', '/foo']
            }
        ]
    }
};

export default awsconfig; 
import * as React from "react";
import ReactDOM from "react-dom";
import App from "./App";

ReactDOM.render(
  <React.Fragment>
    <App title="AWS Demo Site"/>
  </React.Fragment>,
  document.querySelector("#root")
);

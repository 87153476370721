import * as React from 'react';
import { Navigate } from "react-router-dom";

import { Auth } from "aws-amplify";

function signout(){
  try {
      Auth.signOut();
      console.log('signOut ');
  } catch (error) {
      console.log('error signing out: ', error);
  }
}

export default function SignOut() {
  
  signout();
  
  return (
    <Navigate to="/signin" />
  );
}

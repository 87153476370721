import * as React from "react";
import { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Auth, API } from "aws-amplify";
import awsconfig from './aws-exports';

// ******************************************************
//   Config the AWS libraries...
// ******************************************************
Auth.configure(awsconfig);
API.configure(awsconfig);

// ******************************************************
//   (Lazy) load signin components rarely used...
// ******************************************************
const SignUp               = React.lazy(() => import("./auth/SignUp"));
const SignUpConfirm        = React.lazy(() => import("./auth/SignUpConfirm"));
const SignInForgot         = React.lazy(() => import("./auth/SignInForgot"));
const SignInChangePassword = React.lazy(() => import("./auth/SignInChangePassword"));

// ******************************************************
//   Load signin components...
// ******************************************************
import SignIn from "./auth/SignIn";
import SignOut from "./auth/SignOut";

// ******************************************************
//   The main app (Dashboard)...
// ******************************************************
import Dashboard from "./dashboard/Dashboard";

// ******************************************************
//   The router function.
//   Maps URL routes to functions/components such that 
//   they can be linked to from anywhere. 
// ******************************************************
export default function App(props) {

  // Set the title of the page from prop passed
  // from the index.js
  document.title = props.title;

  // Routes 
  return ( 
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" if element={<Dashboard title={props.title}/>} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/confim" element={<SignUpConfirm />} />
          <Route path="/signout" element={<SignOut />} />
          <Route path="/forgot" element={<SignInForgot />} />
          <Route path="/change" element={<SignInChangePassword />} />
        </Routes>
      </Suspense>
    </Router>
  );

}
